.shopbycategory {
  width: 100%;
  position: relative;
  .shopbycategoryheader {
    border-top: 1px solid #ddd;
    padding-top: 30px;
    padding-bottom: 15px;
    .shopbycategoryheader_head {
      color: #000;
      font-weight: 600;
      font-size: 25px;
      text-align: center;
      text-transform: uppercase;
      width: 100%;
      font-family: "Lato", sans-serif;
      line-height: 1.2;
      margin: 0;
    }
    p {
      color: #7f7f7f;
      text-align: center;
      font-size: 16px;
      font-family: "Lato", sans-serif;
      font-weight: 400;
      padding-bottom: 10px;
      &:hover {
        color: #7f7f7f;
      }
    }
  }
  .shopbycategory_image {
    width: 100% !important;
    background-color: #f7f7f7;
    height: 173.5px !important;

    img {
      width: 100%;
    }
    p {
      color: #7f7f7f;
      font-size: 16px;
      &:hover {
        color: #7f7f7f;
      }
    }
  }
  a {
    width: 100% !important;
    height: auto !important;
    margin: 10px;
    font-size: 17px;
    span {
      position: relative;
      width: 100% !important;
    }
    img {
      width: 100% !important;
    }
    &:hover {
      box-shadow: 0px 4px 10px rgb(33 33 33 / 15%);
      color: #555;
    }
  }
}
.shopbycategory_image {
  width: 100% !important;
  background-color: #f7f7f7;

  p {
    color: #7f7f7f;
    font-size: 16px;
    &:hover {
      color: #7f7f7f;
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .shopbycategory {
    width: 100%;
    position: relative;

    .shopbycategory_image {
      img {
        width: 100%;
      }
    }
    a {
      width: 100% !important;
      height: auto !important;
    }
  }
}
.swiper_button_prev_1 {
  position: absolute;
  left: -40px;
  bottom: 35%;
  font-size: 20px;
  cursor: pointer;
}
.swiper_button_next_1 {
  position: absolute;
  right: -40px;
  bottom: 35%;
  font-size: 20px;
  cursor: pointer;
}
@media only screen and (min-width: 220px) and (max-width: 1199px) {
  .swiper_button_next_1 {
    position: absolute;
    right: 12px !important;
    bottom: 35%;
    font-size: 20px;
    z-index: 99;
    cursor: pointer;
  }
  .swiper_button_prev_1 {
    position: absolute;
    left: 12px !important;
    bottom: 35%;
    font-size: 20px;
    z-index: 99;
    cursor: pointer;
  }
  .icon-color {
    p {
      font-size: 14.5px;
    }
  }
}
